/**
 * Takes a list of functions and mounts each by passing in the root HTMLElement
 *
 * @param root
 * @param actions
 */
export function mount (root: RootElement, ...actions: GenericFunction<any>[]): GenericFunction<void> {
  return function (): void {
    actions.forEach((action) => action(root))
  }
}
