// Libraries
import { BehaviorSubject } from 'rxjs'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/browser'

// Components
import { LeadGenForm } from '@components/hearingTest/LeadGenForm'
import { PriceDropForm } from '@components/priceDropForm/PriceDropForm'
import { SubscribeForm } from '@components/footer/SubscribeForm'
import * as StateMachine from '@components/hearingTest/stateMachine'
import AddToCart from '@components/shopping/AddToCart'
import AudioComparison from '@components/audioComparison/AudioComparison'
import AudioComparisonProductDetail from '@components/audioComparison/AudioComparisonProductDetail'
import AudiologistProfile from '@components/audiologists/AudiologistProfile'
import AudiologistSearch from '@components/audiologists/AudiologistSearch'
import BlogFiltersComponent from '@components/BlogFiltersComponent'
import BlogProductAudio from '@components/BlogProductAudio'
import ComparisonSet from '@components/comparison/ComparisonSet'
import ComparisonTable from '@components/comparison/ComparisonTable'
import CreateLead from '@components/shopping/CreateLead'
import CreateLeadNew from '@components/CreateLeadNew'
import ExternalLinks from '@components/ExternalLinks'
import FiltersComponent from '@components/FiltersComponent'
import HearingTest from '@components/hearingTest'
import InterstitialPage from '@components/InterstitialPage'
import ProductionComparison from '@components/comparison/ProductComparison'
import RelatedProducts from '@components/RelatedProducts'
import SplideAbout from '@components/splide/SplideAbout'
import SplideAudiologistReviews from '@components/splide/SplideAudiologistReviews'
import SplideBlogHero from '@components/splide/SplideBlogHero'
import SplideBlogTabs from '@components/splide/SplideBlogTabs'
import SplideHearingTest from '@components/splide/SplideHearingTest'
import SplideHome from '@components/splide/SplideHome'
import SplidePress from '@components/splide/SplidePress'
import SplideProductImages from '@components/splide/SplideProductImages'
import SplideProductNews from '@components/splide/SplideProductNews'
import SplideProductReviews from '@components/splide/SplideProductReviews'

// Modules
import Accordion from '@modules/accordion'
import AccordionCK from '@modules/accordion-ck'
import Anchors from '@modules/anchors'
import ConvertTags from '@modules/convertTags'
import CurrentYear from '@modules/currentYear'
import Dialog from '@modules/dialog'
import GlobalRatingStars from '@modules/ratingStars'
import Nav from '@modules/nav'
import NavDropdowns from '@modules/navDropdowns'
import Tabs from '@modules/tabs'
import Videos from '@modules/videos'

// Core
import { bootstrap } from '@core/bootstrap'
import { createStore } from '@core/store'
import { startDKApp } from '@core/app'

// Persistent store
const store = createStore('soundly', sessionStorage)
const coordinates$ = store.createRecord<Coordinates>('location-search', null)
const searchLabel$ = store.createRecord<string>('search-label', null)

// Session store
const session = createStore('soundly', sessionStorage)
const products$ = session.createRecord<Record<string, any>>('product', {})

// In-memory store
const filteredProducts$ = new BehaviorSubject<Maybe<Record<any, string>[]>>(null)
const filteredPosts$ = new BehaviorSubject<Maybe<Record<any, string>[]>>(null)
const currentScreenState$ = new BehaviorSubject<StateMachine.Steps>(StateMachine.Steps.Step0)
const skip$ = new BehaviorSubject<boolean>(false)

startDKApp(() => {
  // Global
  ConvertTags()
  CurrentYear()
  Dialog()
  Nav()
  NavDropdowns()
  Tabs()
  Accordion()
  AccordionCK()
  Anchors()
  Videos()
  GlobalRatingStars()

  bootstrap('add-to-cart', [AddToCart, CreateLead], {})
  bootstrap('blog-products', [BlogProductAudio], {})
  bootstrap('comparison', [ProductionComparison], { products$ })
  bootstrap('comparison-table', [ComparisonTable, AudioComparison], { products$ })
  bootstrap('comparison-audio-product-detail', [AudioComparisonProductDetail], {})
  bootstrap('audiologist-search', [AudiologistSearch], { coordinates$, searchLabel$ })
  bootstrap('audiologist-profile', [AudiologistProfile], {})
  bootstrap('related-products', [RelatedProducts], {})
  bootstrap('shop-filters', [FiltersComponent], { filteredProducts$, products$ })
  bootstrap('blog-filters', [BlogFiltersComponent], { filteredPosts$ })
  bootstrap('splide-about', [SplideHome], {})
  bootstrap('splide-about-looper', [SplideAbout], {})
  bootstrap('splide-audiologist-reviews', [SplideAudiologistReviews], {})
  bootstrap('splide-blog-hero', [SplideBlogHero], {})
  bootstrap('splide-blog-tabs', [SplideBlogTabs], {})
  bootstrap('splide-hearing-test', [SplideHearingTest], {})
  bootstrap('splide-home', [SplideHome], {})
  bootstrap('splide-press', [SplidePress], {})
  bootstrap('splide-product-images', [SplideProductImages], {})
  bootstrap('splide-product-news', [SplideProductNews], {})
  bootstrap('splide-product-reviews', [SplideProductReviews], {})
  bootstrap('compare-all', [ComparisonSet], { products$ })
  bootstrap('hearing-test', [HearingTest], { currentScreenState$, skip$ })
  bootstrap('hearing-test', [LeadGenForm], {})
  bootstrap('external-link', [ExternalLinks])
  bootstrap('interstitial-page', [InterstitialPage], {})
  bootstrap('subscribe-form', [SubscribeForm])
  bootstrap('price-drop-form', [PriceDropForm])
  bootstrap('lead-gen-form', [CreateLeadNew], {})
})

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE),
})
