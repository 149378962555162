let currentlyExpanding: HTMLElement | null = null

/**
 * Initializes the accordion functionality.
 *
 * @example
 * ```html
 * <div ck-accordion-group> <!-- optional -->
 *   <div ck-accordion>
 *     <button>Accordion 1</button>
 *     <div>Content 1</div>
 *   </div>
 *   <div ck-accordion>
 *     <button>Accordion 2</button>
 *     <div>Content 2</div>
 *   </div>
 * </div>
 */
export default function initAccordion(): void {
  const accordionElements = document.querySelectorAll<HTMLElement>('[ck-accordion]')

  accordionElements.forEach((accordion, index) => {
    const button = accordion.querySelector<HTMLElement>('button')
    const content = button?.nextElementSibling as HTMLElement

    if (!button || !content) return

    ensureAriaControlsAndID(button, content, index)
    setInitialState(button, content)

    button.addEventListener('click', () => toggleAccordion(button, content))
  })
}

/**
 * Sets the initial state for the accordion.
 *
 * @param button - The button element of the accordion section.
 * @param content - The content element of the accordion section.
 */
const setInitialState = (button: HTMLElement, content: HTMLElement): void => {
  button.setAttribute('aria-expanded', 'false')
  content.setAttribute('aria-hidden', 'true')
  content.style.maxHeight = '0'
  content.style.opacity = '0'
  content.style.paddingTop = '0rem'
  content.style.transition = 'max-height .125s ease-out, opacity .25s ease-out'
  button.style.transition = ''
}

/**
 * Toggles the accordion open or closed.
 *
 * @param button - The button element of the accordion section.
 * @param content - The content element of the accordion section.
 */
const toggleAccordion = (button: HTMLElement, content: HTMLElement): void => {
  const isExpanded = button.getAttribute('aria-expanded') === 'true'
  const accordionGroup = button.closest('[ck-accordion-group]')

  const closeAllExcept = (exceptionAccordion: HTMLElement | null) => {
    if (accordionGroup) {
      accordionGroup.querySelectorAll<HTMLElement>('[ck-accordion]').forEach((otherAccordion) => {
        if (otherAccordion !== exceptionAccordion) {
          const otherButton = otherAccordion.querySelector<HTMLElement>('button')
          const otherContent = otherButton?.nextElementSibling as HTMLElement

          if (otherButton && otherContent) {
            collapseAccordion(otherButton, otherContent)
          }
        }
      })
    }
  }

  if (accordionGroup) {
    if (!isExpanded) {
      closeAllExcept(button.parentElement!)
      expandAccordion(button, content)
    } else {
      closeAllExcept(null)
    }
  } else {
    isExpanded ? collapseAccordion(button, content) : expandAccordion(button, content)
  }
}

/**
 * Collapses the accordion section.
 *
 * @param button - The button element of the accordion section.
 * @param content - The content element of the accordion section.
 */
const collapseAccordion = (button: HTMLElement, content: HTMLElement): void => {
  if (currentlyExpanding === content) {
    currentlyExpanding = null
  }

  content.style.opacity = '0'
  content.style.paddingTop = '0rem'
  content.style.maxHeight = '0'
  button.setAttribute('aria-expanded', 'false')
  content.setAttribute('aria-hidden', 'true')
}

/**
 * Expands the accordion section.
 *
 * @param button - The button element of the accordion section.
 * @param content - The content element of the accordion section.
 */
const expandAccordion = (button: HTMLElement, content: HTMLElement): void => {
  currentlyExpanding = content

  content.style.opacity = '1'
  content.style.paddingTop = '1rem'
  const contentHeight = content.scrollHeight
  content.style.maxHeight = `${contentHeight + 16}px`

  button.setAttribute('aria-expanded', 'true')
  content.setAttribute('aria-hidden', 'false')
}

/**
 * Ensures the ARIA controls and ID requirements are set correctly.
 * If the content element doesn't have an ID, it assigns a unique one based on the index.
 * If the button element doesn't have an aria-controls attribute or it doesn't match the content's ID, it sets the aria-controls attribute.
 *
 * @param button - The button element of the accordion section.
 * @param content - The content element of the accordion section.
 * @param index - The index of the accordion section.
 */
const ensureAriaControlsAndID = (button: HTMLElement, content: HTMLElement, index: number): void => {
  content.id = content.id || `accordion-content-${index}`
  button.setAttribute('aria-controls', content.id)
}
