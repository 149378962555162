import { fromEvent } from 'rxjs'

/**
 * Finds all DOM elements with the data attribute of dk-action="target:event", registers the
 * given event, and passes the event and the attributes value as parameters to
 * the provided action
 *
 * @param target
 * @param action
 * @param options
 */
export function on (target: string, action: GenericFunction<any>, options = {}) {
  return function (root: RootElement): void {
    const elements = root.querySelectorAll(`[dk-action^="${target}"]`)

    Array.from(elements as NodeListOf<HTMLElement>).forEach((element) => {
      const [, event] = element.getAttribute('dk-action')!.split(':')
      const values = element.dataset || {}
      fromEvent(element, event, options).subscribe((event) => action(event, { ...values }, element))
    })
  }
}
