export default {
  bootstrap: {
    invalidSubscriptionTarget: `
      When registering a watch target, your target must be an Observable and you must include at least one action

      Example: {
        watch: [{
          target: record$,
          actions: [myAction]
        }, ...]
      }
    `,
  },
}
