import { forTarget, hideElement, showElement } from '@core/content'
import { mount } from '@core/mount'
import type { ComponentActions } from '@core/bootstrap'

export default (root: RootElement): ComponentActions => {

  function handleAudioButtons () {
    const audioButtons = root.querySelectorAll<HTMLButtonElement>('[dk-target="audio-button"]')

    audioButtons.forEach((audioButton) => {
      const audioPlayer = document.createElement('audio')
      const AUDIO_FILE = audioButton.dataset.audioFile!

      function watchAudioButton () {
        let shouldPlay = true
        const audioIconPlay = forTarget<HTMLImageElement>(audioButton, 'audio-button-play')!
        const audioIconPause = forTarget<HTMLImageElement>(audioButton, 'audio-button-pause')!
        if (!audioIconPlay) return console.warn('Missing play icon')
        if (!audioIconPause) return console.warn('Missing pause icon')

        audioButton.setAttribute('aria-label', 'Play demo track')
        hideElement(audioIconPause)

        audioButton.addEventListener('click', function () {
          if (shouldPlay) {
            audioPlayer.pause()
            audioPlayer.src = AUDIO_FILE
            audioPlayer.loop = true
            audioPlayer.play()
            hideElement(audioIconPlay)
            showElement(audioIconPause)
            audioButton.classList.add('playing')
            audioButton.setAttribute('aria-label', 'Pause demo track')
            shouldPlay = false
          } else {
            audioPlayer.pause()
            audioPlayer.loop = false
            hideElement(audioIconPause)
            showElement(audioIconPlay)
            audioButton.classList.remove('playing')
            audioButton.setAttribute('aria-label', 'Play demo track')
            shouldPlay = true
          }
        })
      }

      watchAudioButton()
    })
  }

  return {
    start: mount(root,
      handleAudioButtons,
    ),
  }
}
